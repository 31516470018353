export default {
  meta_title: 'Quantum Computing | PennyLane',
  meta_description:
    'Sit back and learn about quantum computing. Explore key concepts, download and run demonstrations, and view our selection of curated videos.',
  meta_image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/qml/quantumComputing/QCOpenGraph.png',
  top_section: {
    title: 'Quantum Computing',
    sub_title_1:
      'Quantum computing is a research area that extends the set of physical laws classical computers operate on by accessing quantum aspects of the physical world, opening up new ways of processing information.',
    sub_title_2:
      'As a highly interdisciplinary field of quantum science and technology, quantum computing sits at the meeting point of algorithm design, complexity theory, system architecture, and hardware development.',
    link_cards: [
      {
        link: '/qml/what-is-quantum-computing',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/qml/QC.svg',
        alt: '',
        title: 'What is Quantum Computing?',
        description:
          'Understand what quantum computers can do and how we can make them do it.',
        linkText: 'Read more',
      },
      {
        link: '/qml/glossary',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/icons/glossary_icon.png',
        alt: '',
        title: 'Key Terms',
        description:
          'Explore different concepts underpinning quantum computation.',
        linkText: 'Read more',
      },
      {
        link: '/qml/demonstrations',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/icons/demos_icon.png',
        alt: '',
        title: 'Demos',
        description: 'Take a dive into quantum computing and explore the physics, concepts, and algorithms through code.',
        linkText: 'Read more',
      },
    ],
  },
  video_section: {
    sub_title:
      'Sit back and explore quantum computing with our curated selection of expert videos.',
    img_alt: 'Measurements and Quantum Circuits thumbnail',
    title: 'Measurements and Quantum Circuits',
    image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/qml/quantumComputing/YTMeasurements.png',
    videoId: 'jNb_2Nd3Pzc',
  },
}
