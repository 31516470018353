import CardLink from '../../CardLink/CardLink'
import { ICardLink } from '../../../types'
import './style.scss'

interface IHubsLinkCards {
  cards: ICardLink[]
}

const HubsLinkCards = ({ cards }: IHubsLinkCards) => {
  return (
    <div className="HubsCards">
      {cards.map((item, index) => {
        return (
          <div
            key={`${index}-${item.title}`}
            className="HubsCard"
            data-testid="hubs-card"
          >
            <CardLink
              link={item.link}
              icon={item.icon}
              alt={item.alt}
              title={item.title}
              description={item.description}
              linkText={item.linkText}
            />
          </div>
        )
      })}
    </div>
  )
}

export default HubsLinkCards
