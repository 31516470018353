import LinkComponent from '../../LinkComponent/LinkComponent'
import VideoCardInfo from '../../VideoCardInfo/VideoCardInfo'
import './style.scss'

export interface IVideoSection {
  sub_title: string
  title: string
  image?: string
  videoId: string
}

const VideoSection = ({ sub_title, title, image, videoId }: IVideoSection) => {
  return (
    <section className="Subhub__video__section">
      <h2>{sub_title}</h2>

      <div className="Subhub__video__card">
        <VideoCardInfo title={title} videoId={videoId} image={image} />
      </div>
      <LinkComponent href="/qml/videos">See all videos</LinkComponent>
    </section>
  )
}

export default VideoSection
