import HubsLinkCards from '../HubsLinkCards/HubsLinkCards'
import MainTitle from '../../MainTitle/MainTitle'
import { ICardLink } from '../../../types'
import './style.scss'

export interface ITopSection {
  title: string
  sub_title_1: string
  sub_title_2: string
  sub_link?: JSX.Element
  link_cards: ICardLink[]
}

const HubTopSection = ({
  title,
  sub_title_1,
  sub_title_2,
  sub_link,
  link_cards,
}: ITopSection) => {
  return (
    <section className="Top__section">
      <div className="Top__section__info">
        <MainTitle title={title} />
        {sub_title_1 && <h2 className="mb-6">{sub_title_1}</h2>}
        {sub_title_2 && <h2>{sub_title_2}</h2>}
      </div>

      {sub_link && sub_link}

      <HubsLinkCards cards={link_cards} />
    </section>
  )
}

export default HubTopSection
