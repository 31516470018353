import { useState } from 'react'
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs'
import { useCheckBanner } from 'shared-utilities'
import HubTopSection, { ITopSection } from '../TopSection/TopSection'
import VideoSection, { IVideoSection } from '../VideoSection/VideoSection'
import './style.scss'

interface ISubhubProps {
  top_section: ITopSection
  video_section: IVideoSection
}

const Subhub = ({ top_section, video_section }: ISubhubProps) => {
  const [isBannerPresent, setIsBannerPresent] = useState<boolean>(false)

  useCheckBanner(setIsBannerPresent)

  return (
    <>
      <Breadcrumbs pageTitle={top_section.title} />
      <div
        className={`Subhub ${isBannerPresent ? 'Subhub--bannerVisible' : ''}`}
      >
        <HubTopSection
          title={top_section.title}
          sub_title_1={top_section.sub_title_1}
          sub_title_2={top_section.sub_title_2}
          link_cards={top_section.link_cards}
        />

        <hr className="hr-border my-10" />

        <VideoSection
          sub_title={video_section.sub_title}
          title={video_section.title}
          videoId={video_section.videoId}
          image={video_section.image}
        />
      </div>
    </>
  )
}

export default Subhub
